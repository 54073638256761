@import "~bootstrap/scss/bootstrap";
@import "~select2";

#logo {
  width: 200px;
  margin: 10px auto;
  display: block;
}

.footer-copyright{
  text-align: center;
  margin-top: 20px;
}

.error-message {
  color: $danger;
}

.success-message {
  color: $success;
}

.nav-pills {
  margin-bottom: 20px;
}

#campaigns {
  margin-top: 20px;
}

.select2-container{
  display: block;
}